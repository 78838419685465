import React from 'react';
import { getFlowButtonHandler } from '../Dialog';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const AppSection = ({ partner = 'bunq'}) => {
  const { t, i18n } = useTranslation();
  return (
    <section className="app">
      <div className="container">
        <h2 className="app__title">{t('home:StepsSection.title_1')}</h2>
        <div className="app__text">{t('promocode:cta.description')}</div>
        <div className="app__text app__text--green">
          {t('promocode:cta.call')}
        </div>
        <button
          className="btn btn--primary"
          onClick={() =>
            getFlowButtonHandler('https://app.flowyour.money/' + partner + document.location.search)
          }
        >
          {t('promocode:cta.download')}
        </button>
        <small className="app__small">{t('cta.devices_notice')}</small>
      </div>
    </section>
  );
};

export default AppSection;
