import React, { useEffect, useState } from 'react';

const PromoSection = () => {
  const [code, setCode] = useState<string | null>('BUNQ12ABOZ4');

  const getPromoCode = async () => {
    if (!localStorage.getItem('promoCode')) {
      try {
        const response = await fetch(
          'https://api.flowyour.money/u/generate-promo-code?promoCodeType=bunq'
        );
        if (response.ok) {
          const promoCode = await response.text();
          setCode(promoCode);
          // Add Promo code to LS
          localStorage.setItem('promoCode', promoCode);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setCode(localStorage.getItem('promoCode'));
    }
  };

  useEffect(() => {
    getPromoCode();
  }, []);

  return (
    <section className="promo">
      <div className="container">
        <h2 className="promo__title promo__title--primary">Claim your</h2>
        <h2 className="promo__title promo__title--secondary">bunq deal</h2>
        <div className="promo__text">
          Experience 2 months of the best that Flow and bunq have to offer for
          FREE. Automatic payments in the background with real IBAN budgetting
          accounts.
        </div>
        <h3 className="promo__subtitle">Your promo code</h3>
        <div className="promo__code">{code}</div>
      </div>
    </section>
  );
};

export default PromoSection;
