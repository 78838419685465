import React from 'react';
import { getFlowButtonHandler } from '../Dialog';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const StepsSection = ({ partner = 'bunq' }) => {
  const { t, i18n } = useTranslation('promocode');
  return (
    <section className="steps">
      <div className="container">
        <h2 className="steps__title">{t('steps.title.'+partner, 'How to activate this Deal')}</h2>
        <div className="steps__step">{t('steps.step1', '1. Download the Flow app')}</div>
        <div className="steps__step">
          {t('steps.step2', '2. Use your promo code in the Profile tab')}
        </div>
        <div className="steps__step">
          {t('steps.step3.'+partner, '3. Done! You can now enjoy 2 months of Flow+ for free')}
        </div>
        <button
          className="btn btn--primary"
          onClick={() =>
            getFlowButtonHandler('https://app.flowyour.money/' + partner + document.location.search)
          }
        >
          Download Flow
        </button>
      </div>
    </section>
  );
};

export default StepsSection;
